<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <div class="top-nav-login pt-4 pl-5">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          pill
          class="float-right mr-5 btn-auth-non-login"
          style="font-weight: 400 !important;"
          :to="{name:'auth-register'}"
        >
          Sign up for free
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-light"
          class="float-right mr-1 btn-auth-non-login"
          style="color: #fff; font-weight: 400 !important;"
          :to="{name:'auth-login'}"
        >
          Login
        </b-button>

        <b-link
          class="brand-logo"
          href="/"
        >
          <b-img-lazy
            fluid
            :src="imgUrl"
            alt="logo SEOcloud"
            class="img-logo"
            width="340px"
            height="40px"
          />
        </b-link>
      </div>

      <b-col
        lg="6"
        class="d-flex px-2 p-lg-5 form-sign"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-5 mx-auto"
        >
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-light"
            style="color: #ffffff; display: block; text-align: left; width: 40px;"
            class="btn-icon pl-0 mb-0 pb-0"
            :to="{name:'auth-login'}"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              size="25"
            />
          </b-button>

          <b-card-title
            class="font-weight-bold color-text-white"
            title-tag="h1"
            style="font-weight: 700; font-size: 28px; margin-top: 40px; line-height: 1.4;"
          >
            Reset your password
          </b-card-title>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="Enter email"
                    style="height: 52px;"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="disabled"
                class="mt-2 font-weight-bold"
                style="font-weight: 400; border-radius: 39px; height: 52px; font-size: 20px;"
              >
                Send password reset email
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>

      <!-- Right Text-->
      <b-col
        lg="6"
        class="d-none d-lg-flex flex-column m-auto p-5"
      >
        <b-card-title
          class="mb-2 font-weight-bold color-text-white"
          style="font-weight: 400;"
          title-tag="h3"
        >
          The best web scanner to <span style="color: #c31bff;">resolve</span><br>
          technical and SEO problems<span style="color: #c31bff;">.</span>
        </b-card-title>
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img-lazy
            fluid
            :src="ghaphicsImg"
            alt="Login V2"
            width="800"
            height="445"
          />
        </div>
      </b-col>
      <!-- /Right Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BImgLazy, BInputGroupAppend, BInputGroup, BFormCheckbox, BAlert, VBTooltip,
  BCard, BCardBody, BModal, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import { auth } from '@/firebase'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BImgLazy,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BAlert,
    BCard,
    BCardBody,
    BModal,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/logo/logo-seocloud.svg'),
      ghaphicsImg: require('@/assets/images/banner/ghaphics.svg'),
      // validation
      required,
      email,
      disabled: true,
    }
  },
  metaInfo: {
    meta: [
      { name: 'title', content: 'Olvidé Contraseña' },
    ],
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/logo-seocloud.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    userEmail(val) {
      this.disabled = false
    },
  },
  methods: {
    validationForm() {
      this.disabled = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          auth.sendPasswordResetEmail(this.userEmail)
            .then(doc => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Password reset link sent, please check your email.',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
            // console.log(error.code)
              if (error.code === 'auth/wrong-password') {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: 'The password is not correct',
                  },
                })
              } else if (error.code === 'auth/user-not-found') {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: 'Invalid email or password',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: error.message,
                  },
                })
              }
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: error.message,
            //     icon: 'EditIcon',
            //     variant: 'danger',
            //   },
            // })
            })
        }
      })
    },
  },
}
</script>

<style>
@media  (min-width: 0) and (max-width: 991px) {
  .btn-auth-non-login {
    display: none !important;
  }
}

.form-sign {
  padding-top: 25vh !important;
}
@media only screen and (min-width: 600px) {
  .form-sign {padding-top: 25vh !important;}
}
@media only screen and (min-width: 2300px) {
  .form-sign {padding-top: 32vh !important;}
}
@media only screen and (min-width: 3282px) {
  .form-sign {padding-top: 36vh !important;}
}
@media only screen and (min-width: 4100px) {
  .form-sign {padding-top: 38vh !important;}
}
@media only screen and (min-width: 5400px) {
  .form-sign {padding-top: 42vh !important;}
}
body {
  background: url("https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/stripes-bkg.png?alt=media&token=174016c2-d520-4123-b4b7-bead206719ab") 0% 0% no-repeat padding-box,linear-gradient(115deg, #181e38 47%, #7838ff 180%) !important;
}
/* .lnkHv:hover {
  color: #f4d6ff !important;
} */
.modal .modal-header .close {
  transform: none;
  box-shadow: none;
  border-radius: unset;
  background: none;
}
.modal .modal-header .close:hover {
  transform: translate(2px, 2px);
}
.top-nav-login {
  /* background-color: #7838ff; */
  /* background-image: url(/assets/images/pattern-8d0f59e0f30b8e1261b9e36e843460eb.png); */
  /* background-repeat: repeat; */
  border: none;
  height: 55px;
  top: 0px;
  position: absolute;
  width: 100%;
}
.img-logo {
  width: 340px;
  height: 40px;
  position: relative;
  top: 25px;
  left: 20px;
}
.brand-logo-svg{fill: white;height: 38px;}
.account-body {
  position: relative;
  padding: 35px 30px 10px;
  margin-bottom: 1em;
  color: #444;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,.15);
  box-shadow: 1px 1px 1px rgba(0,0,0,.15);
  border-radius: 4px;
  width: 450px
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.text-card-register {
  font-size: 15px;
}
</style>
